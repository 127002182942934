<template>
    <div class="set-height-100">
        <header-tabs :page-tabs="pageTabs"></header-tabs>
        <div class="header-tab-container current" data-tabid="1">
            <div class="stepper-container">
                <stepper-nav :default-active-tab="20" :initiator="'produkte'" @update:activeTab="activeTab = $event" />

                <!-- Content for each stepper-content-inner with accordion effect -->
                <div class="stepper-content">

                    <div :class="['stepper-content-inner', { current: activeTab === 20 }]">
                        <h2>Unbezahlte Auszeit</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Arbeitnehmer
                                        </li>
                                        <li>
                                            Beamte
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>6 Monate</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>Arbeitszeit wird einvernehmlich mit dem Arbeitgeber zeitlich begrenzt zu 100 % ruhend gestellt
                                        </li>
                                        <li>Abwesenheit von mind. 4 Wochen ohne Unterbrechung
                                        </li>
                                        <li>
                                            Jeder Zeitraum von 4 Wochen im selben Versicherungsfall löst eine weitere Zahlung aus (Bsp.: 3 Wochen unbezahlter Urlaub = keine Zahlung, 6 Wochen unbezahlter Urlaub = 1 Zahlung, 8 Wochen unbezahlter Urlaub = 2 Zahlungen, etc.)
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug durch Selbstständige, Richter, Berufs- sowie Zeitsoldaten
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 30 }]">
                        <h2>Temporäre Arbeitszeitverkürzung</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Arbeitnehmer
                                        </li>
                                        <li>
                                            Beamte
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>6 Monate</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>Arbeitszeitreduzierung um mindestens 50 % aufgrund unvorhergesehener Umstände zeitlich begrenzt
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug durch Selbstständige, Richter, Berufs- sowie Zeitsoldaten
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 40 }]">
                        <h2>Pflegezeit</h2>
                        <p>
                            Je nach Pflegesituation unbezahlte Auszeit oder temporäre Arbeitszeitverkürzung.
                        </p>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 50 }]">
                        <h2>Elternzeit</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Arbeitnehmer
                                        </li>
                                        <li>
                                            Beamte
                                        </li>
                                        <li>
                                            Selbstständige
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>6 Monate</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kind nach Ablauf der Wartezeit geboren oder adoptiert
                                        </li>
                                        <li>
                                            Die versicherte Person bezieht Elterngeld nach dem Bundeselterngeld- und Elternzeitgesetz
                                        </li>
                                        <li>
                                            Die versicherte Person darf keiner Beschäftigung von mehr als 30 Wochenstunden nachgehen
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    -
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 60 }]">
                        <h2>Kurzarbeit</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    Arbeitnehmer
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>1 Monat</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Anspruch auf Kurzarbeitergeld nach § 95 SGB III bei betriebsbedingter konjunktureller Kurzarbeit
                                        </li>
                                        <li>
                                            Sozialversicherungspflichtige Beschäftigung von mehr als 15 Stunden/Woche
                                        </li>
                                        <li>
                                            Die Kurzarbeit ist erstmalig nach Ablauf der Wartezeit eingetreten
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Berufs- sowie Zeitsoldaten
                                        </li>
                                        <li>
                                            Kein Leistungsbezug bei freiwilliger Kurzarbeit, Altersteilzeit, Streik und saisonaler Kurzarbeit
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 70 }]">
                        <h2>
                            Arbeitslosigkeit
                        </h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    Arbeitnehmer
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>1 Monat</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>2 Monate</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Mindestens 6 Monate bei demselben Arbeitgeber
                                        </li>
                                        <li>
                                            Bezug von Arbeitslosengeld wegen ALO
                                        </li>
                                        <li>
                                            ALO muss unverschuldet sein
                                        </li>
                                        <li>
                                            Sozialversicherungspflichtige Beschäftigung von mehr als 15 Stunden/Woche
                                        </li>
                                        <li>
                                            Zahlungen ab dem 3. Monat nach Beginn der ALO
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Berufs- sowie Zeitsoldaten
                                        </li>
                                        <li>
                                            Kein Leistungsbezug bei Eigenkündigung sowie nach Auslauf eines befristeten Vertrages
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 80 }]" data-stoerer="Keine Gesundheitsfragen">
                        <h2>Schwere Krankheit</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Beamte
                                        </li>
                                        <li>
                                            Selbstständige
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>1 Monat</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Gilt nur für Beamte und Selbstständige
                                        </li>
                                        <li>
                                            Die ärztliche Diagnose bzw. Bestätigung muss endgültig und eindeutig sein
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug bei Erkrankung in Folge grober Fahrlässigkeit sowie bei Alkoholismus und anderen Suchterkrankungen
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 90 }]">
                        <h2 class="with-subheadline">Arbeitsunfähigkeit</h2>
                        <h5 class="with-subheadline">Inkl. psychischer Erkrankungen</h5>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Arbeitnehmer
                                        </li>
                                        <li>
                                            Beamte
                                        </li>
                                        <li>
                                            Selbstständige
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Monatlich</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>1 Monat (entfällt bei AU durch Unfall)</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>42 Tage (nach jeder Unterbrechung der AU beginnt die Karenzzeit erneut, auch bei gleicher Krankheitsart wie zuvor)</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Nachweis einer ärztlichen Diagnose sowie eines zur Vorlage beim Arbeitgeber benötigten Attests (inkl. voraussichtlicher Dauer der AU)
                                        </li>
                                        <li>
                                            Bei psychischen Erkrankungen muss nach den ersten 3 Krankheitsmonaten ein Nachweis eines niedergelassenen Facharztes für Psychiatrie und Psychotherapie (o.ä.) vorgelegt werden
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug bei Erkrankung in Folge grober Fahrlässigkeit sowie bei Alkoholismus und anderen Suchterkrankungen
                                        </li>
                                        <li>
                                            Kein Leistungsbezug nach Folgen einer medizinisch nicht notwendigen Behandlung
                                        </li>
                                        <li>
                                            Kein Leistungsbezug bei Erhalt einer teilweisen oder vollen Erwerbsminderungsrente
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div :class="['stepper-content-inner', { current: activeTab === 100 }]">
                        <h2 class="with-subheadline">Lebensereignisse</h2>
                        <h5 class="with-subheadline">Geburt/Adoption, Heirat und Scheidung</h5>
                        <table>
                            <tbody>
                            <tr>
                                <td>Mögliche Versicherungsnehmer:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Arbeitnehmer
                                        </li>
                                        <li>
                                            Beamte
                                        </li>
                                        <li>
                                            Selbstständige
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Versicherungsleistung:</td>
                                <td>Gewählte Höhe</td>
                            </tr>
                            <tr>
                                <td>Auszahlungsfrequenz:</td>
                                <td>Einmalzahlung</td>
                            </tr>
                            <tr>
                                <td>Max. Auszahlung je Leistungsfall:</td>
                                <td>1 (je Versicherungsjahr)<br /><small style="font-size: 0.8rem">(Ausnahme Mehrlingsgeburten)</small></td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.wartezeit"><strong>Wartezeit:</strong></span></td>
                                <td>6 Monate</td>
                            </tr>
                            <tr>
                                <td><span v-tooltip="toolTips.karenzzeit"><strong>Karenzzeit:</strong></span></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Voraussetzungen und Besonderheiten:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Nachweis des Lebensereignisses durch: Geburts- bzw. Adoptionsurkunde, Eheurkunde, Lebenspartnerschaftsurkunde oder Scheidungsurkunde
                                        </li>
                                        <li>
                                            Je Versicherungsjahr kann ein Lebensereignis geltend gemacht werden. Ausnahme sind Mehrlingsgeburten. Es erfolgt eine Auszahlung je Neugeborenem.
                                        </li>
                                        <li>
                                            Selbstständige erhalten die doppelte Leistungshöhe ausgezahlt
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Ausschlüsse:</td>
                                <td>
                                    <ul>
                                        <li>
                                            Kein Leistungsbezug bei Erwachsenenadoption oder der Aufnahme von Pflegekindern
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <div class="header-tab-container padded" data-tabid="2">
            <div class="row">
                <div class="col-2">
                    <h2 class="pt-xs">Tarifübersicht</h2>
                </div>
                <div class="col-10">
                    <tariff-options></tariff-options>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepperNav from "../../components/StepperNav";
import {ref} from 'vue';
import TariffOptions from "@/components/TariffOptions.vue";
import HeaderTabs from "@/components/HeaderTabs.vue";

export default {
    name: "Produkte",
    components: {
        HeaderTabs,
        TariffOptions,
        StepperNav,
    },
    setup() {
        const activeTab = ref(10); // default active tab

        // Method to update the active tab
        const setActiveTab = (tabId) => {
            activeTab.value = tabId;
        };

        const toolTips = {
            elternzeit:
                '<h3>Elternzeit</h3>' +
                '<ul>' +
                '<li>Das Elterngeld soll den Einkommenseinbruch nach der Geburt eines Kindes reduzieren und wird von den staatlichen Elterngeldstellen ausgezahlt.</li>' +
                '<li>Elternzeit kann jedes Elternteil in Anspruch nehmen, das in einem Arbeitsverhältnis steht (Vollzeit, Teilzeit, Ausbildung, geringfügige und/oder befristete Beschäftigung).</li>' +
                '<li>Das Basiselterngeld beträgt i. d. R. zwischen 65 % und 67 % des Nettoeinkommens, aber mind. 300 € bzw. max. 1.800 € monatlich.</li>' +
                '</ul>',
                    deckungen:
            '<h3>Max. Zahlungen über alle Deckungen</h3>' +
            '<p>Der Kunde erhält während der gesamten Vertragslaufzeit max. 36 Zahlungen – über alle Deckungen und Schadenfälle hinweg. Die Leistung für den Unfalltod ist hiervon ausgenommen.</p>',
                wartezeit:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst wenn nach der Wartezeit ein Kind geboren oder adoptiert wird, hat der Kunde einen Leistungsanspruch.</p>',
                wartezeit2:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst nach der Wartezeit hat der Kunde einen Leistungsanspruch.</p>',
                karenzzeit:
            '<h3>Karenzzeit</h3>' +
            '<p>Die Karenzzeit ist die leistungsfreie Zeit nach Eintritt des Versicherungsfalles, für die kein Leistungsanspruch geltend gemacht werden kann.</p>',
                kurzzeitpflege:
            '<h3>Kurzzeitpflege</h3>' +
            '<ul>' +
            '<li>Familienpflegezeit / Kurzzeitpflege bezeichnet die teilweise Freistellung für die Pflege eines pflegebedürftigen nahen Angehörigen in häuslicher Umgebung über einen Zeitraum von bis zu 24 Monaten bei einer wöchentlichen Mindestarbeitszeit von 15 Stunden.</li>' +
            '<li>Beschäftigte in Unternehmen mit 25 oder mehr Angestellten haben einen Anspruch auf Familienpflegezeit.</li>' +
            '</ul>',
                verwandte:
            '<h3>Nahe Angehörige</h3>' +
            '<ul>' +
            '<li>Als nahe Angehörige gelten: Großeltern und Eltern, Schwiegereltern, Ehegatten, Lebenspartner oder Partner einer eheähnlichen Gemeinschaft, Stiefeltern, Lebenspartner der Geschwister, Geschwister der Lebenspartner sowie für Partner in lebenspartnerschaftsähnlichen Gemeinschaften, Geschwister, Kinder, Adoptivkinder sowie Kinder oder Adoptivkinder des Ehegatten oder Lebenspartners, Schwieger- und Enkelkinder.</li>' +
            '<li>Pflegekinder gelten nicht als nahe Angehörige.</li>' +
            '</ul>',
                kurzarbeit:
            '<h3>Konjunkturelle Kurzarbeit</h3>' +
            '<ul>' +
            '<li>Konjunkturelle Kurzarbeit ist für ein Unternehmen das Mittel der Wahl, wenn Mitarbeiter aufgrund der wirtschaftlichen Situation unterbeschäftigt sind, aber nicht entlassen werden sollen.</li>' +
            '<li>Sie kann bei Auftragsausfällen durch entsprechende Vereinbarungen zur Reduzierung der Arbeitszeit im Betrieb sehr kurzfristig eingeführt werden.</li>' +
            '</ul>',
                arbeitslosigkeit:
            '<h3>Arbeitslosigkeit</h3>' +
            '<p>Arbeitslose sind Personen, die […] nicht in einem Beschäftigungsverhältnis stehen, eine versicherungspflichtige Beschäftigung suchen und dabei den Vermittlungsbemühungen der Agentur für Arbeit zur Verfügung stehen und sich bei der Agentur für Arbeit arbeitslos gemeldet haben (§ 16 Absatz 1 SGB III).</p>',
                schwereKrankheiten:
            '<h3>Schwere Krankheiten</h3>' +
            '<ul>' +
            '<li>Der Versicherer ordnet folgende Erkrankungen als schwere Krankheiten ein: invasiver Krebs, Herzinfarkt, Schlaganfall, Nierenversagen, Hauptorgantransplantation, Bypassoperation der Koronararterien.</li>' +
            '<li>Mehr Infos in den AVBs unter „Besondere Bedingungen für den Leistungsbaustein Santander Protect bei schweren Krankheiten“</li>' +
            '</ul>',
                grobeFahrlaessigkeit:
            '<h3>Grobe Fahrlässigkeit</h3>' +
            '<p>Es wird grob fahrlässig gehandelt, wenn ein Schaden durch einfache und naheliegende Verhaltensweisen hätte verhindert werden können und diese außer Acht gelassen wurden.</p>',
                arbeitsunfaehigkeit:
            '<h3>Arbeitsunfähigkeit</h3>' +
            '<p>Ein Arbeitnehmer ist arbeitsunfähig, wenn er aufgrund einer Krankheit seine zuletzt ausgeübte Tätigkeit nicht mehr ausführen kann oder die Ausführung der jeweiligen Tätigkeit mit der Gefahr der Verschlimmerung der Krankheit einhergeht.</p>',
                unfalltod:
            '<h3>Unfalltod</h3>' +
            '<p>Ein Unfall liegt vor, wenn die versicherte Person durch ein plötzlich von außen auf den Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine Gesundheitsschädigung mit Todesfolge erleidet.</p>',
        }

        const pageTabs = {
            1: {
                'name': 'Protect'
            },
            2: {
                'name': 'Tarifvarianten'
            }
        }

        return {
            activeTab,
            setActiveTab,
            toolTips,
            pageTabs
        };
    },
};
</script>

<style scoped>
    h2.with-subheadline {
        padding:0;
    }
    h5.with-subheadline {
        padding: 0 0 2rem;
        font-weight: normal;
    }
</style>
