import { createStore } from 'vuex';

export default createStore({
    state: {
        buttonVisible: false,
        buttonAction: null, // Store the function
        buttonCaption: 'Leistungsanfrage',
    },
    mutations: {
        setButtonVisibility(state, isVisible) {
            state.buttonVisible = isVisible;
        },
        setButtonAction(state, action) {
            state.buttonAction = action; // Store the closure here
        },
        setButtonCaption(state, caption) {
            state.buttonCaption = caption;
        },
    },
    actions: {
        updateButton({ commit }, { isVisible, action, caption }) {
            commit('setButtonVisibility', isVisible);
            commit('setButtonAction', action); // Commit the function
            commit('setButtonCaption', caption);
        },
    },
    getters: {
        buttonVisible: (state) => state.buttonVisible,
        buttonAction: (state) => state.buttonAction, // Expose the function through the getter
        buttonCaption: (state) => state.buttonCaption,
    },
});
