<template>
  <nav class="header-nav">
    <div class="header-tabs" v-if="pageTabs">
      <span v-for="(pageTab, index) in pageTabs" :key="pageTab.name" :data-tab="index" class="tab" :class="{'current': index === '1'}">{{ pageTab.name }}</span>
    </div>
  </nav>
</template>
<script>
export default {
  name: "HeaderTabs",
  props: {
    'pageTabs': Object
  },
};
</script>
