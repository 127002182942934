<template>
    <header>
        <div class="container">
            <div class="row">
                <div class="col-12 dis-flex flex-direction-row-reverse flex-wrap-wrap flex-align-items-center">
                    <router-link :to="{ name: 'Overview' }" class="logo">
                        <img src="@/assets/img/santander-logo-no-claim.svg"/>
                    </router-link>
                    <div class="top-link">
                        <div class="top-link" v-if="buttonVisible">
                            <!-- Use buttonAction directly in @click -->
                            <button class="header-button stoerer-button" @click="buttonAction">{{ buttonCaption }}</button>
                        </div>
                    </div>
                    <div class="box-wrapper" v-if="!isNotStart && !infoHidden">
                        <div class="info-box">Die Anwendung bitte auf den Monitor ziehen,<br> um Darstellungsfehler zu
                            vermeiden.
                        </div>
                        <div class="info-sign"></div>
                    </div>
                    <div class="placeholder" v-if="!isNotStart && infoHidden">
                    </div>
                    <div class="header-headline" v-if="!isNotStart">
                        <h1 class="c-primary1">Alles auf einen Blick</h1>
                    </div>
                    <div id="pageTabsNavHolder">
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {useRoute, useRouter} from "vue-router";

export default {
    name: 'Header',
    props: {
        isNotStart: Boolean,
    },
    setup() {
        const store = useStore();
        const infoHidden = computed(() => false);
        const router = useRouter();
        const route = useRoute();

        // Access Vuex state properties as computed properties
        const buttonVisible = computed(() => store.getters.buttonVisible);
        const buttonAction = computed(() => store.getters.buttonAction);
        const buttonCaption = computed(() => store.getters.buttonCaption);

        const reloadRoute = () => {
            router.replace({ path: '/redirect' }).then(() => {
                router.replace({ path: route.fullPath });
            });
        };

        return {
            buttonVisible,
            buttonAction,
            buttonCaption,
            infoHidden,
            reloadRoute
        };
    },
};
</script>

<style scoped>
.top-link {
    position: absolute;
    top: 2rem;
    left: 1.5rem;
}
</style>
