<template>
    <div class="set-height-100">
        <div class="stepper-container">
            <stepper-nav :default-active-tab="10" :initiator="'leistungsanfrage'" @update:activeTab="activeTab = $event" />

            <!-- Content for each stepper-content-inner with accordion effect -->
            <div class="stepper-content">
                <h1>Leistungsanfrage</h1>
                <p>Was müssen du und deine Kunden bei der Anfrage einer Versicherungsleistung beachten?</p>

                <!-- Content for tab 10 -->
                <div :class="['stepper-content-inner', { current: activeTab === 10 }]">
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                <h2>Allgemein</h2>
                                <ul>
                                    <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich anzuzeigen.</li>
                                    <li>Bei Bedarf zur Klärung der Leistungspflicht kann der Versicherer weitere Auskünfte einholen.</li>
                                </ul>
                            </div>
                            <div class="col-8 pr-md">
                                <e-claims gap="4"></e-claims>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Content for tab 20 -->
                <div :class="['stepper-content-inner', { current: activeTab === 20 }]">
                    <h2><span>Unbezahlte Auszeit</span></h2>
                    <p><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>Das Eintreten des Versicherungsfalls ist uns unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
                        <li>Sie haben uns jegliche Änderungen in Bezug auf den Zeitraum einer unbezahlten Auszeit umgehend mitzuteilen.</li>
                    </ul>
                    <p><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>Z. B.: vom Arbeitgeber ausgestellte Dokumente, Bestätigungen der jeweiligen ausstellenden Behörden</li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 30 }]">
                    <h2><span>Temporäre Arbeitszeitverkürzung</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>
                            Das Eintreten des Versicherungsfalls ist uns unverzüglich (innerhalb von 14 Tagen) anzuzeigen.
                        </li>
                        <li>
                            Sie haben uns jegliche Änderungen in Bezug auf den Zeitraum einer temporären Arbeitszeitverkürzung umgehend mitzuteilen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Z. B.: vom Arbeitgeber ausgestellte Dokumente, Bestätigungen der jeweiligen ausstellenden Behörden
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 40 }]">
                    <h2><span>Pflegezeit</span></h2>
                    <p>
                        S. unbezahlte Auszeit oder temporäre Arbeitszeitverkürzung
                    </p>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 50 }]">
                    <h2><span>Elternzeit</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>
                            Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.
                        </li>
                        <li>
                            Während der Leistungsdauer ist das Bestehen der Elternzeit nachzuweisen, jegliche Änderungen in Bezug auf den Zeitraum der Elternzeit sind mitzuteilen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Z. B.: vom Arbeitgeber ausgestellte Dokumente, Bestätigungen der jeweiligen ausstellenden Behörden
                        </li>
                        <li>
                            Selbstständige: Benachrichtigung über den Bezug des Elterngeldes
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 60 }]">
                    <h2><span>Kurzarbeit</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>Das Eintreten von konjunktureller Kurzarbeit ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.
                        </li>
                        <li>
                            Während der Leistungsdauer ist das Bestehen der Kurzarbeit nachzuweisen und jegliche Änderungen bzgl. der Kurzarbeit sind mitzuteilen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Bestätigung des Arbeitgebers mit Start- und Enddatum der Kurzarbeit und Grund der Kurzarbeit
                        </li>
                        <li>
                            Bestätigung des Jobcenters über die Anerkennung der Kurzarbeit nach § 95 SGB III mit Start- und Enddatum
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 70 }]">
                    <h2><span>Arbeitslosigkeit</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>
                            Beginn und Ende der unverschuldeten Arbeitslosigkeit sind unverzüglich in Textform anzuzeigen.
                        </li>
                        <li>
                            Der erste und jeder weitere Bewilligungs- oder Änderungsbescheid der Agentur für Arbeit ist unverzüglich vorzulegen, aus dem sich die Dauer des Bezuges von Arbeitslosengeld ergibt.
                        </li>
                        <li>
                            Sowohl jegliche eventuelle Reduzierung des Arbeitslosengeldes oder des Anspruchs als auch die nachträgliche Aberkennung des Arbeitslosengeldanspruchs durch die Agentur für Arbeit ist unverzüglich mitzuteilen.
                        </li>
                        <li>
                            Das ununterbrochene Fortbestehen der unverschuldeten Arbeitslosigkeit, der regelmäßige Bezug von Arbeitslosengeld und die aktiven Bemühungen um eine neue Arbeitsstelle jeden Monat sind unverzüglich nachzuweisen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Das mit Kündigungsgründen versehene Kündigungsschreiben oder eine Bescheinigung des Arbeitgebers über den Kündigungsgrund ist vorzulegen.
                        </li>
                        <li>
                            Eine vom letzten Arbeitgeber für die Agentur für Arbeit ausgefüllte Arbeitsbescheinigungskopie ist vorzulegen.
                        </li>
                        <li>
                            Eine Bescheinigung der Agentur für Arbeit ist vorzulegen, aus der sich ergibt, seit wann die versicherte Person bei der Agentur für Arbeit ununterbrochen als arbeitslos und als arbeitssuchend gemeldet ist.
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 80 }]">
                    <h2><span>Schwere Krankheit</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (innerhalb von 14
                            Tagen) anzuzeigen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Zum Nachweis der schweren Krankheit müssen eine ärztlich attestierte Bescheinigung und auf Verlangen der Versicherung ein ärztliches Zeugnis auf deren Originalvordruck eingereicht werden. Der behandelnde Arzt muss bestätigen, wann er den Verdacht bezüglich der schweren Krankheit geäußert hat.
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 90 }]">
                    <h2><span>Arbeitsunfähigkeit (Inklusive Psychischer Erkrankung)</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>
                            Der Eintritt einer den leistungsfreien Zeitraum (Karenzzeit) von 42 Tagen übersteigenden Arbeitsunfähigkeit ist unverzüglich (binnen 14 Tagen) anzuzeigen.
                        </li>
                        <li>
                            Die Wiederherstellung der Arbeitsfähigkeit ist dem Versicherer unverzüglich anzuzeigen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Ärztlich attestierte Arbeitsunfähigkeitsbescheinigung und auf Verlangen des Versicherers ein ärztliches Zeugnis auf deren Originalvordruck.
                        </li>
                        <li>
                            Die Arbeitsunfähigkeitsbescheinigung muss die zugrunde liegende(n) Diagnose(n) und die voraussichtliche Dauer der Arbeitsunfähigkeit enthalten sowie den Anforderungen gemäß der <i>"Arbeitsunfähigkeits-Richtlinie des Gemeinsamen Bundesausschusses"</i> entsprechen.
                        </li>
                        <li>
                            Zudem kann eine medizinische Untersuchung durch einen vom Versicherer bestimmten, zugelassenen und praktizierenden Arzt verlangt werden.
                        </li>
                        <li>
                            Bei Arbeitsunfähigkeit aufgrund einer psychischen Erkrankung muss diese nach den ersten drei Krankheitsmonaten von einem niedergelassenen Facharzt für Psychiatrie und Psychotherapie, Facharzt für Psychosomatische Medizin und Psychotherapie oder Facharzt für Kinder- und Jugendpsychiatrie und Psychotherapie bescheinigt werden.
                        </li>
                    </ul>
                </div>

                <div :class="['stepper-content-inner', { current: activeTab === 100 }]">
                    <h2><span>Lebensereignisse</span></h2>
                    <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
                    <ul>
                        <li>
                            Das Eintreten des Versicherungsfalls ist unverzüglich (binnen 14 Tagen) anzuzeigen.
                        </li>
                    </ul>
                    <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
                    <ul>
                        <li>
                            Geburts- oder Adoptionsurkunde
                        </li>
                        <li>
                            Ehe- oder Lebenspartnerschaftsurkunde
                        </li>
                        <li>
                            Scheidungsurkunde
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import StepperNav from "../../components/StepperNav";
import eClaims from "../../components/eClaims";
import { ref } from 'vue';

export default {
    name: "Leistungsanfrage",
    components: {
        StepperNav,
        eClaims,
    },
    setup() {
        const activeTab = ref(10); // default active tab

        // Method to update the active tab
        const setActiveTab = (tabId) => {
            activeTab.value = tabId;
        };

        return {
            activeTab,
            setActiveTab,
        };
    },
};
</script>
