<template>
    <div class="header-tab-container current padded">
        <h2>Allgemeine Versicherungsbedingungen</h2>
        <div>
            <a href="/pdf/AVBs_Protect_20240924.pdf" target="_blank">
                AVBs_Santander-Protect_20240924.pdf
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
a {
    color: #0077c8;
    text-decoration: underline;
    font-size: 1.6rem;
    font-weight: bold;
}

.avb-wrapper {
    .stepper-content {
        padding-right: 4rem;
    }

    p, ul, ol, dl {
        max-width: inherit;
    }
}
</style>

<script>
export default {
    name: "AVB",
};
</script>
