<template>
    <div class="qr-container">
        <p class="mb-none font-size-md">Einen Leistungsfall selber melden:</p>
        <h2 class="pt-none text-transform-none">eClaims</h2>

        <div class="grid-container">
            <div class="qr-wrapper">
                <img src="@/assets/img/qrcode_www.eclaims.cnpsantander.de.png" />
                <span class="qr-subline">
                    <a target="_blank" href="https://www.cnpsantander.de">www.cnpsantander.de</a>
                </span>
            </div>
            <div class="qr-wrapper">
                <img src="@/assets/img/qrcode_play.google.com.png" />
                <span class="qr-subline">
                  Android
                </span>
            </div>
            <div class="qr-wrapper">
                <img src="@/assets/img/qrcode_apps.apple.com.png" />
                <span class="qr-subline">
                  iPhone
                </span>
            </div>

            <!-- Contact Methods -->
            <div class="contact-method email">
                <h2 class="pt-none mx-none text-transform-none">Per E-Mail</h2>
                <a href="mailto:schadenservice@ger.cnpsantander.com">schadenservice@ger.cnpsantander.com</a>
            </div>
            <div class="contact-method post">
                <h2 class="pt-none mx-none text-transform-none">Per Post</h2>
                <p>
                    CNP Santander Insurance Europe DAC
                    <br />Postfach 32 10 80
                    <br />40425 Düsseldorf
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "eClaims",
    props: {
        gap: {
            type: String,
            default: '1'
        },
    },
};
</script>
