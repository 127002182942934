<template>
    <div class="footer-nav-wrapper">
        <div class="grid-split -split-3">
            <div>
                <ul class="nav-list" :class="{
          'active':
            isRoute('Produkte')
          }">
                    <li class="nav-list-elem">
                        <router-link class="tab-link" active-class="active" exact :to="{ name: 'Produkte' }" @click="reloadPage">
                            <span>Produkte</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div>
                <ul class="nav-list" :class="{
          'active':
             isRoute('VorsorgeRechner')
          }">
                    <li class="nav-list-elem">
                        <router-link class="tab-link" active-class="active" exact :to="{ name: 'VorsorgeRechner' }" @click="reloadPage">
                            <span>Einkommenslücken-Rechner</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div>
                <ul class="nav-list" :class="{
          'active':
             isRoute('Leistungsanfrage') ||
             isRoute('AVB')
          }">
                    <li class="nav-list-elem">
                        <router-link class="tab-link" active-class="active" exact :to="{ name: 'Leistungsanfrage' }" @click="reloadPage">
                            <span>Leistungsanfrage</span>
                        </router-link>
                    </li>

                    <li class="nav-list-elem">
                        <router-link class="tab-link" active-class="active" exact :to="{ name: 'AVB' }" @click="reloadPage">
                            <span>AVB</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>
import navStore from "@/store/navStore";

export default {
    name: "FooterNav",
    methods: {
        reloadPage(e) {

            navStore.commit('setButtonVisibility', false);

            const headerTabStepperContainer = document.querySelectorAll('.header-tab-container');
            const headerTabStepper = document.querySelectorAll('.header-tabs .tab');

            if (headerTabStepperContainer.length > 0) {
                headerTabStepperContainer.forEach(container => container.classList.remove('current'));
                headerTabStepper.forEach(tab => tab.classList.remove('current'));
                headerTabStepperContainer[0].classList.add('current');
            }

            if (e.target.innerHTML === 'Schadenmeldung') {
                const allStepperContent = document.querySelectorAll('.stepper-content-inner');
                const allStepperNavItems = document.querySelectorAll('.stepper-nav-list > li');

                if (allStepperContent.length > 0 && allStepperNavItems.length > 0) {
                    allStepperContent.forEach(content => content.classList.remove('current'));
                    allStepperNavItems.forEach(item => item.classList.remove('current'));
                    const firstStepperContent = document.querySelector('.stepper-content-inner[data-tab="0"]');
                    if (firstStepperContent) {
                        firstStepperContent.classList.add('current');
                    }
                }
            }
        },
        isRoute(text) {
            return this.$route.matched.some(({name}) => name === text);
        }
    }
};
</script>
