import {createRouter, createWebHashHistory} from 'vue-router';
import Overview from './../views/Overview';
import Produkte from './../views/pages/Produkte';
import VorsorgeRechner from './../views/pages/VorsorgeRechner';
import Leistungsanfrage from './../views/pages/Leistungsanfrage';
import AVB from './../views/pages/AVB';

const routes = [
  {
    name: 'Overview',
    path: '/',
    component: Overview,
  },
  {
    name: 'Produkte',
    path: '/nav1/Produkte',
    component: Produkte,
  },
  {
    name: 'VorsorgeRechner',
    path: '/nav2/VorsorgeRechner',
    component: VorsorgeRechner,
  },
  {
    name: 'Leistungsanfrage',
    path: '/nav3/Leistungsanfrage',
    component: Leistungsanfrage,
  },
  {
    name: 'AVB',
    path: '/nav3/AVB',
    component: AVB,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: '-is-active',
  routes,
});

export default router;
