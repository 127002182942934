import { createApp } from 'vue';
import App from './App.vue';
import FloatingVue from 'floating-vue';
import router from './router';
import './assets/scss/main.scss';
import 'floating-vue/dist/style.css';

//If you're using the compatibility build and need to configure options
import { configureCompat } from 'vue';
import navStore from "@/store/navStore";
configureCompat({
  RENDER_FUNCTION: false,
});

const app = createApp(App, { defaultHtml: true });

app.use(FloatingVue, {
  themes: {
    tooltip: {
      html: true,
    },
  },
});
app.use(navStore);
app.use(router);

// Define the genericTabs function
const genericTabs = function() {
  const curr = 'current';
  let tabsNavTabs;
  let tabsNav = document.querySelectorAll('.header-nav');
  let tabsContent = document.querySelectorAll('.header-tab-container');

  for (let k = 0; k < tabsNav.length; k++) {
    let chosenStepperNav = tabsNav[k];
    tabsNavTabs = chosenStepperNav.querySelectorAll('.tab');

    if (tabsNavTabs) {
      tabsNavTabs.forEach(function(tabsNavTab) {
        let tabId = tabsNavTab.dataset.tab;
        let tabContainer = document.querySelector(
            '.header-tab-container[data-tabid="' + tabId + '"]'
        );

        tabsNavTab.addEventListener('click', function () {
          for (let i = 0; i < tabsNavTabs.length; i++) {
            tabsNavTabs[i].classList.remove(curr);
          }

          let listElems = document.querySelectorAll('.stepper-nav-list li');
          for (let l = 0; l < listElems.length; l++) {
            listElems[l].classList.remove(curr);
          }

          for (let j = 0; j < tabsContent.length; j++) {
            tabsContent[j].classList.remove(curr);
          }

          this.classList.add(curr);
          if (tabContainer) {
            tabContainer.classList.add(curr);
          }
        });
      });
    }
  }
};

// Add event listeners on window load
window.addEventListener('load', () => {
  genericTabs();
});

app.mixin({
  mounted() {
    this.$nextTick(function () {
      genericTabs();
    });
  },
});

app.mount('#app');
