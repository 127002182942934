<template>
    <div>
        <div :class="['stepper-nav', initiator]">
            <ul class="stepper-nav-list" data-name="Premium">
                <li v-for="tab in premiumTabs" :key="tab.id" :class="{ current: activeTab === tab.id }" @click="selectTab(tab.id)">
                    {{ tab.name }}
                </li>
            </ul>
            <ul class="stepper-nav-list" data-name="Standard">
                <li v-for="tab in standardTabs" :key="tab.id" :class="{ current: activeTab === tab.id }" @click="selectTab(tab.id)">
                    {{ tab.name }}
                </li>
            </ul>
            <ul class="stepper-nav-list" data-name="Basis">
                <li v-for="tab in basisTabs" :key="tab.id" :class="{ current: activeTab === tab.id }" @click="selectTab(tab.id)">
                    {{ tab.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, toRefs, watch} from 'vue';
import {useStore} from "vuex";

export default {
    name: 'StepperNav',
    props: {
        initiator: String,
        defaultActiveTab: Number,
    },
    emits: ['update:activeTab'], // Declare the emitted event
    setup(props, { emit }) {
        const store = useStore();
        const { defaultActiveTab } = toRefs(props);
        const activeTab = ref(defaultActiveTab.value); // default tab

        const updateButtonIfInitiatorAndTabMatch = () => {
            if (props.initiator === 'leistungsanfrage') {
                store.dispatch('updateButton', {
                    isVisible: activeTab.value !== 10,
                    action: () => {
                        selectTab(10);
                    },
                    caption: 'Leistungsanfrage',
                });
            }
        };

        watch(activeTab, () => {
            updateButtonIfInitiatorAndTabMatch();
        });

        onMounted(() => {
            emit('update:activeTab', defaultActiveTab.value); // Set the default tab
        });

        // Define the tabs
        const premiumTabs = [
            { id: 20, name: 'Unbezahlte Auszeit' },
            { id: 30, name: 'Temporäre Arbeitszeitverkürzung' },
            { id: 40, name: 'Pflegezeit' },
            { id: 50, name: 'Elternzeit' },
        ];
        const standardTabs = [
            { id: 60, name: 'Kurzarbeit' },
            { id: 70, name: 'Arbeitslosigkeit' },
            { id: 80, name: 'Schwere Krankheit' },
        ];
        const basisTabs = [
            { id: 90, name: 'Arbeitsunfähigkeit' },
            { id: 100, name: 'Lebensereignisse' },
        ];

        // Emit the active tab when a tab is selected
        const selectTab = (tabId) => {
            activeTab.value = tabId;
            emit('update:activeTab', tabId); // Emit active tab to parent component
        };

        return {
            activeTab,
            premiumTabs,
            standardTabs,
            basisTabs,
            selectTab,
        };
    },
};
</script>

<style scoped>
.stepper-nav-list li {
    cursor: pointer;
}

.current {
    font-weight: bold;
}
</style>
