<template>
  <div class="column-chart-item">
    <div class="column-chart-item-visual" :class="{'icon-green': (number <= 0)}" :data-amount="result.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })" :data-icon="icon">
      <div class="column-chart-item-visual-inner" :style="{ 'height': percentage }"></div>
      <div v-show="multiplicator < 1" class="difference-number" :class="{'show': number > 0}">{{ offset }}</div>
    </div>
    <div class="column-chart-item-subline">{{ subline }}</div>
  </div>
</template>
<script>
export default {
  name: "columnChartItem",
  props: {
    'number': null,
    'fix': null,
    'multiplicator': Number,
    'icon': String,
    'subline': String,
    'calculationMethod': String,
  },
  computed: {
    result() {
      if(this.calculationMethod == "parentalLeave"){
        return ((this.number * this.multiplicator) > 1800) ? 1800 : (this.number * this.multiplicator);
      }else if(this.calculationMethod == "statutoryPensionInsurance"){
        return ((this.number * this.multiplicator) > 2600) ? 2600 : (this.number * this.multiplicator);
      }
      return (this.number * this.multiplicator);
    },
    offset() {
       return (this.number - this.result).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
    },
    percentage() {
      return (this.number > 0 ? 'calc(' + (this.result / this.number * 100).toFixed(2) + '% + 4px)' : 0)
    },
  }
};
</script>
