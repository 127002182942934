<template>
  <div>
      <img src="@/assets/img/tarifuebersicht_neu.jpg" />
  </div>
</template>
<script>
export default {
  name: "TariffOptions"
};
</script>

<style scoped>
    img {
        width: 80%;
        height: auto;
    }
</style>
